import React from 'react';
import Home from './pages/Home';

const AppRoutes = [
    {
        path: '/',
        element: <Home />,
    },
];

export default AppRoutes;
