import React from 'react';
import ide from '../../assets/images/ide.png';

import './Terminal.scss';
import './Terminal-media.scss';

function Terminal() {
    return (
        <section className="terminal">
            <div className="terminal-lines">
                <div className="tall">
                    <div className="short"></div>
                </div>
            </div>

            <div className="terminal-content">
                <div className="terminal-upper">
                    <div className="upper-text">
                        <span className="terminal-head">
                            Developed for Africa
                        </span>
                        <h1 className="terminal-title">
                            Unleashing Pan-African Trade
                        </h1>
                        <p className="terminal-desc">
                            We seek to offer dynamic, streamlined solutions that foster
                            seamless collaboration and trade among diverse regions
                            of our breathtaking continent, empowering individuals
                            to transact securely and sustainably.
                        </p>
                    </div>
                    <div className="terminal-pic">
                        <img src={ide} alt="terminal" />
                    </div>
                </div>

                <div className="terminal-details">
                    <div className="box">
                        <h4 className="head">Banking Wallets</h4>
                        <p className="desc">
                            We seek to deliver a digitised banking experience
                            tailor made for African merchants, hustlers and traders.
                        </p>
                    </div>

                    <div className="box">
                        <h4 className="head">Trading Platforms</h4>
                        <p className="desc">
                            The platforms we develop intend to richly empower
                            Africans to virtually trade across the phsical borders.
                        </p>
                    </div>

                    <div className="box">
                        <h4 className="head">Delivery Systems</h4>
                        <p className="desc">
                            Providing a fast and quality service that ensures
                            that products and commodities are delivered everywhere within
                            the continent
                        </p>
                    </div>

                    <div className="box">
                        <h4 className="head">Payments Management</h4>
                        <p className="desc">
                            Create applications and platforms that improve the ease of making
                            regular payments, and offering more flexibility.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Terminal;
