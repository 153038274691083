import React from 'react';
import Hero from 'components/hero/Hero';
import Terminal from 'components/terminal/Terminal';
import Approach from 'components/approach/Approach';

function Home() {
    return (
        <main>
            <Hero />
            <Terminal />
            <Approach />
        </main>
    );
}

export default Home;
