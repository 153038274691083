import React from 'react';
import './Approach.scss';
import './Approach-media.scss';

function Approach() {
    return (
        <section className="approach">
            <div className="approach-content">
                <span className="approach-head">Why Rusero</span>
                <div className="approach-title">
                    <h2>A Customer-Experience enriched approach to commerce</h2>
                </div>
                <div className="approach-details">
                    <div className="box">
                        <h4 className="head">Closely Aligned to Users</h4>
                        <p className="desc">
                            We have very close timely interactions with
                            the users of our services to ensure that we
                            constantly ship out the services that make
                            their lives easier.
                        </p>
                    </div>

                    <div className="box">
                        <h4 className="head">Continuous Improvements</h4>
                        <p className="desc">
                            We seek to continuously improve our products
                            and features and remain ahead of the tech advancements
                            at any given time.
                        </p>
                    </div>

                    <div className="box">
                        <h4 className="head">Reliability</h4>
                        <p className="desc">
                            Our systems are hghly scalable and redundant.
                            We will always seek to be certified to the
                            highest industry standards and only implore the
                            best operation procedures in our production.
                        </p>
                    </div>

                    <div className="box">
                        <h4 className="head">Embracing the Future</h4>
                        <p className="desc">
                            We employ the latest Ai tools to continuously optimise
                            our operations so that our service remains efficient,
                            and affordable.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Approach;
