import React from 'react';
import desktop from '../../assets/images/desktop.jpg';
import phone from '../../assets/images/phone1.png';

import './Hero.scss';
import './Hero-media.scss';

function Hero() {
    return (
        <section className="hero">
            <div>
                <div className="hero-title-container">
                    <div className="hero-title-container">
                        <h1 className="title title-main ">
                            Convenient Digital
                            Services for Africa
                        </h1>
                        <p className="title title__second">
                            Convenient Digital
                            Services for Africa
                        </p>
                        <p className="title title__overlay">
                            Convenient Digital
                            Services for Africa
                        </p>
                    </div>
                </div>

                <div className="hero-desc-container">
                    <p className="desc">
                        The vision is to empower individuals throughout the continent
                        to effortlessly engage in transactions, trade, collaborate, and
                        cooperate with anyone across all corners of Africa.
                    </p>
                </div>
            </div>

            <div className="hero-pictures">
                <img className="desktop" src={desktop} alt="desktop" />
                <img className="phone" src={phone} alt="phone" />
            </div>
        </section>
    );
}

export default Hero;
